import { parsePhoneNumber } from 'libphonenumber-js';
import { normalizePhoneNumber } from './utils';

const MAX_LENGTH_PHONE = 18;

export const validatePhone = (countryCode: any, authPhone: string) => {
    let error;
    if (
        (authPhone.length > 0 && authPhone.trim() === '') ||
        (authPhone && isNaN(Number(authPhone)))
    ) {
        error = 'Phone number includes wrong symbols';
    }

    if (!error && authPhone.length > 9 && authPhone.length < MAX_LENGTH_PHONE) {
        const parsed = parsePhoneNumber(authPhone, countryCode);
        error = !parsed?.isValid() ? 'Invalid Phone number' : undefined;
    }

    return error;
};

const createErrorMessage = (
    label: string,
    errorType: string,
    ruleData?: any,
) => {
    let errorMsg = '';
    switch (errorType) {
        case 'required':
            errorMsg = `${label} is required`;
            break;
        case 'minLength':
            errorMsg = `${label} should be more than ${ruleData}`;
            break;
        case 'maxLength':
            errorMsg = `${label} should be less than ${ruleData}`;
            break;
        case 'mayContain': {
            errorMsg = `${label} ${ruleData}`;
            break;
        }
        case 'firstChar': {
            errorMsg = `First char of ${label} ${ruleData}`;
            break;
        }
        case 'shouldNotContain': {
            errorMsg = `${label} ${ruleData}`;
            break;
        }
        case 'shouldEqual': {
            errorMsg = `${label} ${ruleData}`;
            break;
        }
        case 'allowedSize': {
            errorMsg = `${label} ${ruleData}`;
            break;
        }
        case 'allowedFormats': {
            errorMsg = `${label} ${ruleData}`;
            break;
        }
        case 'hasLowerCase': {
            errorMsg = `${label} should has at least one lowercase letter`;
            break;
        }
        case 'hasUppercase': {
            errorMsg = `${label} should has at least one uppercase letter`;
            break;
        }
        case 'hasNumbers': {
            errorMsg = `${label} should has at least one number`;
            break;
        }
        case 'hasSymbols': {
            errorMsg = `${label} should has at least one symbol ${ruleData}`;
            break;
        }
        case 'onlyLatins': {
            errorMsg = `${label} should has only latins chars`;
            break;
        }
    }
    return errorMsg;
};

export const generalValidationRules = {
    firstName: {
        validate: {
            minLength: (str: string) =>
                str?.length < 2
                    ? createErrorMessage('First name', 'minLength', 2)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 64
                    ? createErrorMessage('First name', 'maxLength', 64)
                    : undefined,
            firstChar: (str: string) =>
                /^[a-zA-Z]/.test(str) ||
                createErrorMessage(
                    'First name',
                    'firstChar',
                    'should be a letter',
                ),
            mayContain: (str: string) =>
                !/^[A-Za-z\s\-'.]*$/.test(str)
                    ? createErrorMessage(
                          'First name',
                          'mayContain',
                          'may contain only latin chars and apostrophe, hyphen, space and dot',
                      )
                    : undefined,
            shouldNotContain: (str: string) =>
                /[-|'|.| ]{2}/.test(str)
                    ? createErrorMessage(
                          'First name',
                          'shouldNotContain',
                          'shouldn’t contain a few symbols in a row',
                      )
                    : undefined,
        },
    },
    lastName: {
        validate: {
            minLength: (str: string) =>
                str?.length < 2
                    ? createErrorMessage('Last name', 'minLength', 2)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 64
                    ? createErrorMessage('Last name', 'maxLength', 64)
                    : undefined,
            firstChar: (str: string) =>
                /^[a-zA-Z]/.test(str) ||
                createErrorMessage(
                    'Last name',
                    'firstChar',
                    'should be a letter',
                ),
            mayContain: (str: string) =>
                !/^[A-Za-z\s\-'.]*$/.test(str)
                    ? createErrorMessage(
                          'Last name',
                          'mayContain',
                          'may contain only latin chars and apostrophe, hyphen, space and dot',
                      )
                    : undefined,
            shouldNotContain: (str: string) =>
                /[-|'|.|\s]{2}/.test(str)
                    ? 'shouldn’t contain a few symbols in a row'
                    : undefined,
        },
    },
    tags: {
        validate: {
            required: (str: string[]) =>
                !str?.length
                    ? createErrorMessage('Tags', 'required')
                    : undefined,
            mayContain: (str: string[] | string) => {
                const validStr = typeof str === 'object' ? str.join(' ') : str;
                return !/^[A-Za-z0-9\s]*$/.test(validStr)
                    ? createErrorMessage(
                          'Tags',
                          'mayContain',
                          'only letters and digits may be entered to the “Tags” field and the tags should be divided by space',
                      )
                    : undefined;
            },
        },
    },
    address: {
        validate: {
            minLength: (str: string) =>
                str?.length < 4
                    ? createErrorMessage('Address', 'minLength', 4)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 120
                    ? createErrorMessage('Address', 'maxLength', 120)
                    : undefined,
            mayContain: (str: string) =>
                !/^[A-Za-z0-9\s\-'.&/]*$/.test(str)
                    ? createErrorMessage(
                          'Address',
                          'mayContain',
                          'should contain only latin chars, digits, apostrophes, dashes, spaces and dots',
                      )
                    : undefined,
        },
    },
    cityId: {
        validate: {
            minLength: (str: string) =>
                str?.length < 3
                    ? createErrorMessage('City', 'minLength', 3)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 26
                    ? createErrorMessage('City', 'maxLength', 26)
                    : undefined,
            firstChar: (str: string) =>
                /^[a-zA-Z]/.test(str) ||
                createErrorMessage('City', 'firstChar', 'should be a letter'),
            mayContain: (str: string) =>
                !/^[A-Za-z\s\-']*$/.test(str)
                    ? createErrorMessage(
                          'City',
                          'mayContain',
                          'may contain only latin chars and apostrophe, spaces and dashes',
                      )
                    : undefined,
            shouldNotContain: (str: string) =>
                /[-|'|\s ]{2}/.test(str)
                    ? createErrorMessage(
                          'City',
                          'shouldNotContain',
                          'shouldn’t contain a few symbols in a row',
                      )
                    : undefined,
        },
    },
    zip: {
        validate: {
            minLength: (str: string) =>
                str?.length < 5
                    ? createErrorMessage('ZIP / Postal code', 'minLength', 5)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 10
                    ? createErrorMessage('ZIP / Postal code', 'maxLength', 10)
                    : undefined,
            mayContain: (str: string) =>
                !/^[A-Za-z0-9\s\-]*$/.test(str)
                    ? createErrorMessage(
                          'ZIP / Postal code',
                          'mayContain',
                          'should contain only digits, letters, spaces and dashes',
                      )
                    : undefined,
        },
    },
    country: {
        validate: {
            required: (str: string) =>
                !str?.length
                    ? createErrorMessage('Country', 'required')
                    : undefined,
        },
    },
    state: {
        validate: {
            required: (str: string) =>
                !str?.length
                    ? createErrorMessage('State / Province', 'required')
                    : undefined,
        },
    },
    areaOfLaw: {
        validate: {
            required: (str: string) =>
                !str?.length
                    ? createErrorMessage('Type of practice', 'required')
                    : undefined,
        },
    },
    email: {
        validate: {
            required: (str: string | undefined) =>
                !str?.length
                    ? createErrorMessage('Email', 'required')
                    : undefined,
            maxLength: (str: string | undefined) =>
                (str?.length || 0) > 256
                    ? createErrorMessage('Email', 'maxLength', 256)
                    : undefined,
            mayContain: (str: string | undefined) =>
                !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    str || '',
                )
                    ? createErrorMessage(
                          'Wrong or Invalid email address.',
                          'mayContain',
                          'Please correct and try again',
                      )
                    : undefined,
        },
    },
    phone: {
        validate: {
            required: (str: string | undefined) =>
                !str?.length
                    ? createErrorMessage('Phone', 'required')
                    : undefined,
            minLength: (str: string | undefined) => {
                const normalStr = normalizePhoneNumber(str || '');

                return normalStr?.length < 12 // 12 digit with +1
                    ? createErrorMessage(
                          '',
                          'shouldEqual',
                          'Invalid phone number',
                      )
                    : undefined;
            },
            maxLength: (str: string | undefined) => {
                const normalStr = normalizePhoneNumber(str || '');
                return normalStr?.length > MAX_LENGTH_PHONE
                    ? createErrorMessage('Phone', 'maxLength', MAX_LENGTH_PHONE)
                    : undefined;
            },

            firstChar: (str: string | undefined) => {
                const normalStr = normalizePhoneNumber(str || '');
                return (
                    /^[+|0-9]/.test(normalStr) ||
                    createErrorMessage(
                        'Phone',
                        'firstChar',
                        'should be a plus or digit',
                    )
                );
            },
            mayContain: (str: string | undefined) => {
                const normalStr = normalizePhoneNumber(str || '');
                return !/^[+]?[0-9]*$/.test(normalStr)
                    ? createErrorMessage(
                          'Wrong or Invalid phone.',
                          'mayContain',
                          'Please correct and try again',
                      )
                    : undefined;
            },
        },
    },
};

export const referralValidationRules = {
    ...generalValidationRules,
    description: {
        validate: {
            required: (str: string) =>
                !str?.length
                    ? createErrorMessage('Description', 'required')
                    : undefined,
            minLength: (str: string) =>
                str.length < 5
                    ? createErrorMessage('Description', 'minLength', 5)
                    : undefined,
            maxLength: (str: string) =>
                str.length > 2064
                    ? createErrorMessage('Description', 'maxLength', 2064)
                    : undefined,
        },
    },
    percentage: {
        validate: {
            required: (val: number) =>
                !val ? createErrorMessage('Referral %', 'required') : undefined,
            minLength: (val: number) =>
                val < 0
                    ? createErrorMessage('Referral %', 'minLength', 0)
                    : undefined,
            maxLength: (val: number) =>
                val > 50
                    ? createErrorMessage('Referral %', 'maxLength', 50)
                    : undefined,
        },
    },
    agreement: {
        validate: {
            required: (str?: boolean) => {
                return !str
                    ? createErrorMessage('Agreement', 'required')
                    : undefined;
            },
        },
    },
};

export const myCasesUpdateStatusRules = {
    status: {
        validate: {
            required: (val: number) =>
                !val ? createErrorMessage('Status', 'required') : undefined,
        },
    },
    comment: {
        validate: {
            required: (val: number) =>
                !val ? createErrorMessage('Comment', 'required') : undefined,
            minLength: (str: string) =>
                str.length < 5
                    ? createErrorMessage('Comment', 'minLength', 5)
                    : undefined,
            maxLength: (str: string) =>
                str.length > 2064
                    ? createErrorMessage('Comment', 'maxLength', 2064)
                    : undefined,
        },
    },
    scheduledTime: {
        validate: {
            required: (val: number) =>
                !val
                    ? createErrorMessage('Consultation time', 'required')
                    : undefined,
        },
    },
    priceOfConsultation: {
        validate: {
            required: (val: number) =>
                !val
                    ? createErrorMessage('Consultation Price', 'required')
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 7
                    ? createErrorMessage(
                          'Price of Consultation',
                          'maxLength',
                          1000000,
                      )
                    : undefined,
        },
    },
};

export const kycValidationRules = {
    ...generalValidationRules,
    license: {
        validate: {
            minLength: (str: string) =>
                str?.length < 2
                    ? createErrorMessage('License number', 'minLength', 2)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 20
                    ? createErrorMessage('License number', 'maxLength', 20)
                    : undefined,
            mayContain: (str: string) =>
                !/^[A-Za-z0-9\-\s.]*$/.test(str)
                    ? createErrorMessage(
                          'License number',
                          'mayContain',
                          'may contain only digits, latin chars and dash, space and dot',
                      )
                    : undefined,
        },
    },
    experience: {
        validate: {
            minLength: (str: string) =>
                str?.length < 1
                    ? createErrorMessage('Years in business', 'minLength', 1)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 3
                    ? createErrorMessage('Years in business', 'maxLength', 3)
                    : undefined,
            mayContain: (str: string) =>
                !/^[0-9]*$/.test(str)
                    ? createErrorMessage(
                          'Years in business',
                          'mayContain',
                          'may contain only digits',
                      )
                    : undefined,
        },
    },

    licensePhotoUrl: {
        validate: {
            required: (str: string) =>
                !str.length
                    ? createErrorMessage('License photo URL', 'required')
                    : undefined,
        },
    },
    licensePhotoFile: {
        validate: {
            required: (file: FileList) =>
                !file && !file[0]
                    ? createErrorMessage('Select file for upload', 'required')
                    : undefined,
            allowedFormats: (file: FileList) => {
                let isValid;
                if (!!file && !!file[0] && file[0].type) {
                    const [, type] = file[0].type.split('/');

                    isValid = !['jpg', 'jpeg', 'png', 'pdf'].includes(type)
                        ? createErrorMessage(
                              '',
                              'allowedFormats',
                              'Upload only: .jpeg, .pdf, .png.',
                          )
                        : undefined;
                }
                return isValid;
            },
            allowedSize: (file: FileList) =>
                file && file[0] && file[0].size > 5 * 1024 * 1024
                    ? createErrorMessage(
                          'allowedSize',
                          'The size limit for images is 5.0 MB.',
                      )
                    : undefined,
        },
    },
    unit: {
        validate: {
            mayContain: (str: string) =>
                !/^[A-Za-z0-9\-\s.]*$/.test(str)
                    ? createErrorMessage(
                          'Unit',
                          'mayContain',
                          'may contain only latin chars and dash, space and dot',
                      )
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 5
                    ? createErrorMessage('Unit', 'maxLength', 5)
                    : undefined,
        },
    },
};

export const accountValidationRules = {
    ...generalValidationRules,
    userPhoto: {
        validate: {
            required: (file: FileList | null) =>
                !file && !file?.[0]
                    ? createErrorMessage(
                          '',
                          'Select file for upload',
                          'required',
                      )
                    : undefined,
            allowedFormats: (file: FileList | null) => {
                let isValid;
                if (!!file && !!file[0]) {
                    //ToDO remove type by PRD-934
                    const [, type] = file[0].type.split('/');

                    isValid = !['jpg', 'jpeg', 'png'].includes(type)
                        ? createErrorMessage(
                              '',
                              'allowedFormats',
                              'Upload only: .jpeg, .png',
                          )
                        : undefined;
                }
                return isValid;
            },
            allowedSize: (file: FileList | null) =>
                file && file[0] && file[0].size > 5 * 1024 * 1024
                    ? createErrorMessage(
                          '',
                          'allowedSize',
                          'The size limit for images is 5.0 MB.',
                      )
                    : undefined,
        },
    },
    jobInformation: {
        validate: {
            required: (str: string | undefined) =>
                !str?.length
                    ? createErrorMessage('Legal specialty', 'required')
                    : undefined,
            minLength: (str: string | undefined) =>
                (str?.length || 0) < 5
                    ? createErrorMessage('Legal specialty', 'minLength', 5)
                    : undefined,
            maxLength: (str: string | undefined) =>
                (str?.length || 0) > 70
                    ? createErrorMessage('Legal specialty', 'maxLength', 70)
                    : undefined,
        },
    },
    about: {
        validate: {
            required: (str: string) =>
                !str?.length
                    ? createErrorMessage('About', 'required')
                    : undefined,
            minLength: (str: string) =>
                str.length < 5
                    ? createErrorMessage('About', 'minLength', 5)
                    : undefined,
            maxLength: (str: string) =>
                str.length > 250
                    ? createErrorMessage('About', 'maxLength', 250)
                    : undefined,
        },
    },
    language: {
        validate: {
            required: (str: string) =>
                !str?.length
                    ? createErrorMessage('About', 'required')
                    : undefined,
        },
    },
    timeZone: {
        validate: {
            required: (str: string) =>
                !str?.length
                    ? createErrorMessage('About', 'required')
                    : undefined,
        },
    },
    dateFormat: {
        validate: {
            required: (str: string) =>
                !str?.length
                    ? createErrorMessage('About', 'required')
                    : undefined,
        },
    },
};

export const authValidationRules = {
    authPhone: {
        validate: {
            required: (str: string) =>
                !str?.length
                    ? createErrorMessage('Phone', 'required')
                    : undefined,
            minLength: (str: string) =>
                str?.length < 10
                    ? createErrorMessage('Phone', 'minLength', 10)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > MAX_LENGTH_PHONE
                    ? createErrorMessage('Phone', 'maxLength', MAX_LENGTH_PHONE)
                    : undefined,
        },
    },
    password: {
        validate: {
            required: (str?: string) =>
                !str ? createErrorMessage('Password', 'required') : undefined,
            minLength: (str: string) =>
                str?.length < 8
                    ? createErrorMessage('Password', 'minLength', 8)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 64
                    ? createErrorMessage('Password', 'maxLength', 64)
                    : undefined,
            hasLowerCase: (str: string) =>
                str.toUpperCase() === str
                    ? createErrorMessage('Password', 'hasLowerCase')
                    : undefined,
            hasUppercase: (str: string) =>
                str.toLowerCase() === str
                    ? createErrorMessage('Password', 'hasUppercase')
                    : undefined,
            hasNumbers: (str: string) =>
                !/\d/.test(str)
                    ? createErrorMessage('Password', 'hasNumbers')
                    : undefined,
            hasSymbols: (str: string) =>
                !/[()*_\-!#$%^&*,."\'\][]/.test(str)
                    ? createErrorMessage(
                          'Password',
                          'hasSymbols',
                          'except white space',
                      )
                    : undefined,
            onlyLatins: (str: string) =>
                !/^[a-zA-Z0-9()*_\-!#$%^&*,."\'\][]+$/.test(str)
                    ? createErrorMessage('Password', 'onlyLatins')
                    : undefined,
        },
    },
    oldPassword: {
        validate: {
            required: (str?: string) =>
                !str
                    ? createErrorMessage('Old password', 'required')
                    : undefined,
            minLength: (str: string) =>
                str?.length < 8
                    ? createErrorMessage('Old password', 'minLength', 8)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 64
                    ? createErrorMessage('Old password', 'maxLength', 64)
                    : undefined,
            hasLowerCase: (str: string) =>
                str.toUpperCase() === str
                    ? createErrorMessage('Old password', 'hasLowerCase')
                    : undefined,
            hasUppercase: (str: string) =>
                str.toLowerCase() === str
                    ? createErrorMessage('Old password', 'hasUppercase')
                    : undefined,
            hasNumbers: (str: string) =>
                !/\d/.test(str)
                    ? createErrorMessage('Old password', 'hasNumbers')
                    : undefined,
            hasSymbols: (str: string) =>
                !/^[^\s]+$/.test(str)
                    ? createErrorMessage(
                          'Old password',
                          'hasSymbols',
                          'except white space',
                      )
                    : undefined,
            onlyLatins: (str: string) =>
                !/^[a-zA-Z0-9()*_\-!#$%^&*,."\'\][]+$/.test(str)
                    ? createErrorMessage('Password', 'onlyLatins')
                    : undefined,
        },
    },
    newPassword: {
        validate: {
            required: (str?: string) =>
                !str
                    ? createErrorMessage('New password', 'required')
                    : undefined,
            minLength: (str: string) =>
                str?.length < 8
                    ? createErrorMessage('New password', 'minLength', 8)
                    : undefined,
            maxLength: (str: string) =>
                str?.length > 64
                    ? createErrorMessage('New password', 'maxLength', 64)
                    : undefined,
            hasLowerCase: (str: string) =>
                str.toUpperCase() === str
                    ? createErrorMessage('New password', 'hasLowerCase')
                    : undefined,
            hasUppercase: (str: string) =>
                str.toLowerCase() === str
                    ? createErrorMessage('New password', 'hasUppercase')
                    : undefined,
            hasNumbers: (str: string) =>
                !/\d/.test(str)
                    ? createErrorMessage('New password', 'hasNumbers')
                    : undefined,
            hasSymbols: (str: string) =>
                !/^[^\s]+$/.test(str)
                    ? createErrorMessage(
                          'New password',
                          'hasSymbols',
                          'except white space',
                      )
                    : undefined,
            onlyLatins: (str: string) =>
                !/^[a-zA-Z0-9()*_\-!#$%^&*@,."\'\][]+$/.test(str)
                    ? createErrorMessage('Password', 'onlyLatins')
                    : undefined,
        },
    },
    confirmNewPassword: {
        validate: {
            required: (str?: string) =>
                !str
                    ? createErrorMessage(
                          'Field confirm new password',
                          'required',
                      )
                    : undefined,
        },
    },
};
